<template>
  <div class="mt-10" :class="{'min-h-100vh':$route.path=='/company'}">
    <v-container v-show="show">
      <h2 class="f-20 mb-5">会社概要</h2>
      <div class="company_box relative-center">
        <div>
          <div class="th bold border-top text-left">
            <div>社名</div>
          </div>
          <div class="td text-left border-top">
            {{ Info.company }}
          </div>
          <div class="clear" />

          <div v-show="Info.founding">
            <div class="th bold text-left">
              <div>設立日</div>
            </div>
            <div class="td text-left">
              {{ moment(Info.founding).format("yyyy年M月D日") }}
            </div>
            <div class="clear" />
          </div>

          <div v-show="Info.capital">
            <div class="th bold text-left">
              <div>資本金</div>
            </div>
            <div class="td text-left">
              {{ Info.capital }}
            </div>
            <div class="clear" />
          </div>

          <div v-show="Info.address">
            <div ref="addTh" class="th bold text-left relative">
              <div>本社所在地</div>
            </div>
            <div ref="addTd" class="td text-left">
              <span v-show="Info.zip">〒{{ Info.zip }}</span
              ><br />
              {{ Info.address }}
            </div>
            <div class="clear" />
          </div>
          <!--
                  <div>
                    <div ref="add2Th" class="th bold  text-left relative">
                        <div>北九州多角化<br class="disno-xs">事業部</div>
                    </div>
                    <div ref="add2Td" class="td text-left ">
                        <span v-show="Info.zip">〒808-0025</span><br>
                        福岡県北九州市若松区中川町4-6&nbsp;CREVoビル2F
                    </div>
                    <div class="clear" />
                </div>
-->
          <div v-show="Info.ceo">
            <div class="th bold text-left">
              <div>代表者</div>
            </div>
            <div class="td text-left">
              {{ Info.ceo }}
            </div>
            <div class="clear" />
          </div>

          <div v-show="Info.tel || Info.fax">
            <div class="th bold text-left">
              <div>連絡先</div>
            </div>
            <div class="td text-left">
              <span v-show="Info.tel">TEL：{{ Info.tel }}</span>
              <span v-show="Info.tel && Info.fax" class="px-4 disno-xs"></span>
              <br class="dis-xs" />
              <span v-show="Info.fax">FAX：{{ Info.fax }}</span>
            </div>
            <div class="clear" />
          </div>
          <div v-show="Info.job">
            <div ref="jobTh" class="th bold text-left">
              <div>事業内容</div>
            </div>
            <div ref="jobTd" class="td text-left" style="white-space: pre-wrap">
              {{ Info.job }}
            </div>
            <div class="clear" />
          </div>

          <div v-show="Info.web">
            <div class="th bold text-left">
              <div>WEBサイト</div>
            </div>
            <div class="td text-left">
              <a :href="Info.web" target="blank">{{ Info.web }}</a>
            </div>
            <div class="clear" />
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";
Vue.prototype.moment = moment;

export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    Info() {
      return this.$store.getters["user/info"];
    },
    Station() {
      return this.$store.getters["user/station"];
    },
  },
  created() {
    // if (!this.Info.address || (this.Info.type == 1 && this.Station.length == 0) || !this.Info.starthh) {
    //     window.location.href = "/notfound";
    //     return false;
    // }
    this.show = true;
  },
  mounted() {
    if (window.innerWidth > 599) {
      this.$refs.addTh.style.height = this.$refs.addTd.clientHeight + "px";
      this.$refs.addTh2.style.height = this.$refs.addTd2.clientHeight + "px";
      this.$refs.jobTh.style.height = this.$refs.jobTd.clientHeight + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/company.scss";
</style>
